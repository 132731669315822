import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import COLORS from '../../../constants/colors';
import RiskLevel from '../Products/RiskLevel/RiskLevel';
import MButton from '../../../shared/MButton';
import PopupMenu from '../../../shared/PopupMenu';
import ProductIcons from '../../../constants/ProductIcons';
import { locations } from '../../../routes/locations';
import { ProductFile } from '../../../types/product';
import { getUploadedDocumentURL } from '../../../helpers/api_deprecated';
import { singleDownload } from '../../../helpers/request';
import { ProductPositions } from '../../../utils/ProductPositions';
import { formatNumber } from '../../../utils/number';
import { FundAttachmentType } from '../../../store_deprecated/types/indexFundTypes';

interface ProductCardProps {
  product?: any;
  onClickFund: () => void;
  onDelete: () => void;
  products?: any;
}

export const ProductCard: React.FC<ProductCardProps> = ({ product, products, onClickFund, onDelete }) => {
  const [productFiles, setProductFiles] = useState<ProductFile[]>([]);
  // const [invested, setInvested] = useState(0);
  const { isSubscribed, id, userCategory, files, originalPortfolio } = product;

  const { nameEn, nameAr, descriptionEn, descriptionAr } = product.productType ?? {};

  const matchMobileView = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  const onClickShowDetails = () => {
    if (isSubscribed) {
      navigate(locations.productDashboard(), {
        state: { productId: id },
      });
    } else {
      navigate(`${locations.riskSurvey()}?page=recommendedPortfolios`, {
        state: {
          from: locations.dashboard(),
          type: 'list',
          currentProductId: id,
        },
      });
    }
  };

  const onFileDownloadHandler = async (file: ProductFile) => {
    const response = await getUploadedDocumentURL({
      access: 'private',
      fileId: file.documentLink,
    });
    if (response?.url) {
      await singleDownload(response.url, {
        rename: () => file.documentLink,
      });
    }
  };

  // Watch for product.files and transform them
  useEffect(() => {
    const factSheet = isEnglish ? FundAttachmentType.FACT_SHEET : FundAttachmentType.FACT_SHEET_AR;
    const targetFiles = isSubscribed ? originalPortfolio?.files : files;

    if (targetFiles?.length === 0 || !targetFiles) return;

    const factSheetFile = targetFiles.filter((item: any) => item.portfolioFileType.name === factSheet);

    const nonFactSheetFiles = targetFiles.filter(
      (item: any) =>
        item.portfolioFileType.name !== FundAttachmentType.FACT_SHEET &&
        item.portfolioFileType.name !== FundAttachmentType.FACT_SHEET_AR,
    );

    const finalFiles = [...factSheetFile, ...nonFactSheetFiles];

    const productFileList = finalFiles.map((file: ProductFile & { fileId: string }) => ({
      id: file.id,
      documentLink: file.fileId,
      type: file.type || (file.portfolioFileType?.name as string),
    }));

    setProductFiles(productFileList);
  }, [files, originalPortfolio]);

  // useEffect(() => {
  //   const cashValue = product?.cacheAllocationValue;
  //   const userIndexFunds = product?.subscribedPortfolioAssociatedIndexFund;

  //   const portfolioPositions = new ProductPositions({
  //     realTime: true,
  //     portfolio: {
  //       ...product,
  //       associatedIndexFunds: product?.subscribedPortfolioAssociatedIndexFund,
  //     },
  //     userIndexFunds,
  //   });

  //   const values = portfolioPositions.calculateTotalValues(portfolioPositions.calculatePositions());
  //   const totalInvested = values.totalCostBase + cashValue;

  //   setInvested(totalInvested);
  // }, []);

  const generateStatusProps = (status: string) => {
    switch (status) {
      case 'PENDING':
        return { background: COLORS.MINT, name: t('orders.status.PENDING') };
      case 'APPROVED':
        return { background: COLORS.PRIMARY_BLUE, name: t('orders.status.APPROVED') };
      case 'IN_PROGRESS':
        return { background: COLORS.SECONDARY_GREEN, name: t('orders.status.IN_PROGRESS') };
      default:
        return { background: COLORS.ORANGE, name: t('orders.status.CANCELED') };
    }
  };

  return (
    <Box
      sx={{
        p: { xs: '20px', md: '24px 32px' },
        borderRadius: '24px',
        backgroundColor: COLORS.WHITE,
        gap: '16px',
        display: 'grid',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: { xs: 'center', sm: 'flex-start' },
          justifyContent: { xs: 'space-between', md: 'unset' },
          img: {
            mt: { xs: 'unset', md: '2px' },
          },
        }}
      >
        <img
          className={isEnglish ? 'product-icon product-icon__en' : 'product-icon product-icon__ar'}
          src={`/images/icons/${ProductIcons[nameEn?.toLowerCase()] || 'control.svg'}`}
          alt=""
        />
        {!matchMobileView && (
          <Box sx={{ ml: 2 }}>
            <Typography sx={{ fontSize: { xs: 20, md: 20 }, fontWeight: 700 }}>
              {isEnglish ? nameEn : nameAr}
            </Typography>
            <Typography sx={{ fontSize: { xs: 14, md: 14 }, fontWeight: 400 }}>
              {isEnglish ? descriptionEn : descriptionAr}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexShrink: 0,
            // flexDirection: matchMobileView ? 'column-reverse' : 'row',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'unset' },
            gap: 5,
            ml: { xs: 0, md: 'auto' },
          }}
        >
          {isSubscribed && (
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                background: generateStatusProps(product?.currentStatus).background,
              }}
              px="12px"
              py="6px"
              borderRadius="24px"
            >
              <img src="/images/indicator.svg" alt="indicator" />
              <Typography sx={{ fontSize: { xs: 12 }, fontWeight: 500, color: COLORS.WHITE }}>
                {generateStatusProps(product?.currentStatus).name}
              </Typography>
            </Box>
          )}
          {!matchMobileView && (
            <PopupMenu
              showDelete={!isSubscribed}
              onDelete={onDelete}
              files={productFiles}
              onFileDownload={onFileDownloadHandler}
            />
          )}
        </Box>
        {matchMobileView && (
          <PopupMenu
            showDelete={!isSubscribed}
            onDelete={onDelete}
            files={productFiles}
            onFileDownload={onFileDownloadHandler}
          />
        )}
      </Box>
      {matchMobileView && (
        <Box sx={{ ml: { xs: 0, sm: 2 } }}>
          <Typography sx={{ fontSize: { xs: 20, md: 20 }, fontWeight: 700 }}>{isEnglish ? nameEn : nameAr}</Typography>
          <Typography sx={{ fontSize: { xs: 14, md: 14 }, fontWeight: 400 }}>
            {isEnglish ? descriptionEn : descriptionAr}
          </Typography>
        </Box>
      )}
      <Box sx={{ mt: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: { xs: '16px', md: '32px' } }}>
          <Box>
            <Typography sx={{ fontSize: { xs: 12, md: 14 }, fontWeight: 400 }}>
              {t('dashboard.newDashboard.userCategory')}
            </Typography>
            <Typography sx={{ fontSize: { xs: 16, md: 20 }, fontWeight: 700 }}>
              {isEnglish ? userCategory?.nameEn : userCategory?.nameAr}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: { xs: 12, md: 14 }, fontWeight: 400 }}>
              {t('dashboard.newDashboard.riskProfile')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <RiskLevel maxScore={userCategory?.maxScore} onlyRating />
              {/* <Typography sx={{ ml: 2, fontSize: { xs: 16, md: 16 }, fontWeight: 500 }}>
                {isEnglish ? userCategory.nameEn : userCategory.nameAr}
              </Typography> */}
            </Box>
          </Box>
          {isSubscribed && (
            <Box>
              <Typography sx={{ fontSize: { xs: 12, md: 14 }, fontWeight: 400 }}>
                {t('dashboard.newDashboard.invested')}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography sx={{ fontSize: { xs: 16, md: 20 }, fontWeight: 700 }}>
                  {formatNumber(product?.totalInvestedAmount, i18n.language, 2)}
                </Typography>
                <Typography sx={{ fontSize: { xs: 8, md: 8 }, fontWeight: 700 }}>{t('unit.SAR')}</Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'flex' }, justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
          <MButton
            variant="contained"
            onClick={onClickFund}
            typographyProps={{
              sx: {
                padding: '10px 20px',
                borderRadius: '10px',
                border: 'none',
                color: COLORS.DARK_GREY,
                fontSize: 14,
                fontWeight: 600,
              },
            }}
            buttonProps={{
              sx: {
                borderRadius: '10px',
                backgroundImage: 'none',
                backgroundColor: '#EDEFF2',
                mt: { xs: 4, md: 'unset' },
                width: { xs: '100%', md: '50%' },
                height: '40px',
                '&:hover': {
                  backgroundColor: '#EDEFF2',
                  boxShadow: 'none',
                },
                img: {
                  mr: 2,
                },
              },
            }}
          >
            <img src="/images/icons/add-fund.svg" alt="" />
            {t(isSubscribed ? 'dashboard.newDashboard.addFunds' : 'dashboard.newDashboard.invest')}
          </MButton>
          <MButton
            variant="contained"
            onClick={onClickShowDetails}
            typographyProps={{
              sx: {
                padding: '10px 20px',
                border: 'none',
                color: COLORS.DARK_GREY,
                fontSize: 14,
                fontWeight: 600,
              },
            }}
            buttonProps={{
              sx: {
                borderRadius: '10px',
                backgroundColor: '#EDEFF2',
                backgroundImage: 'none',
                width: { xs: '100%', md: '50%' },
                mt: { xs: 2, md: 'unset' },
                height: '40px',
                '&:hover': {
                  backgroundColor: '#EDEFF2',
                  boxShadow: 'none',
                },
              },
            }}
          >
            {t('dashboard.newDashboard.showDetails')}
          </MButton>
        </Box>
      </Box>
    </Box>
  );
};
